import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Context } from '../redux/_context'
import queryString from 'query-string'
import axios from 'axios'
// import { Link } from 'gatsby'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function End() {
  const { state, dispatch } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()

  //API call
  //http://localhost:8888/patients/caregiver-survey/?jid=101&sid=123456&cid=camp_if_101&tid=tact_id_101&mid=m_id_101
  //sid and jid must be numeric

  //get the querystring
  const parsedQString = queryString.parse(window?.location?.search)

  const showValuesForDebug = () => {
    /**
     * For Debugging
     */
    console.log('VALUES BEING SUBMITTED:')
    console.log({
      Q1: state.answer_1.question
        ? state.answer_1.question.qid + '_' + state.answer_1.answerValue
        : null,
      Q2: state.answer_2.question
        ? state.answer_2.question.qid + '_' + state.answer_2.answerValue
        : null,
      Q3: state.answer_3.question
        ? state.answer_3.question.qid + '_' + state.answer_3.answerValue
        : null,
      Q4: state.answer_4.question
        ? state.answer_4.question.qid + '_' + state.answer_4.answerValue
        : null,
      Q5: state.answer_5.question
        ? state.answer_5.question.qid + '_' + state.answer_5.answerValue
        : null,
      Q6: state.answer_6.question
        ? state.answer_6.question.qid + '_' + state.answer_6.answerValue
        : null,
    })
  }

  const getData = async (_recaptchaToken) => {
    if (parsedQString['sid']) {
      try {
        const options = {
          url: 'https://melodic-ladybug-6bfc23.netlify.app/api/caregiver-survey-service/',
          method: 'POST',
          headers: { 'Content-Type': 'text/plain;charset=utf-8' },
          data: {
            CampaignId:
              parsedQString['cid'] !== undefined ? parsedQString['cid'] : null,
            JobId:
              parsedQString['jid'] !== undefined ? parsedQString['jid'] : null,
            EndoMdmId:
              parsedQString['mid'] !== undefined ? parsedQString['mid'] : null,
            SubscriberId:
              parsedQString['sid'] !== undefined ? parsedQString['sid'] : null,
            TacticId:
              parsedQString['tid'] !== undefined ? parsedQString['tid'] : null,
            Q1: state.answer_1.question
              ? state.answer_1.question.qid + '_' + state.answer_1.answerValue
              : null,
            Q2: state.answer_2.question
              ? state.answer_2.question.qid + '_' + state.answer_2.answerValue
              : null,
            Q3: state.answer_3.question
              ? state.answer_3.question.qid + '_' + state.answer_3.answerValue
              : null,
            Q4: state.answer_4.question
              ? state.answer_4.question.qid + '_' + state.answer_4.answerValue
              : null,
            Q5: state.answer_5.question
              ? state.answer_5.question.qid + '_' + state.answer_5.answerValue
              : null,
            Q6: state.answer_6.question
              ? state.answer_6.question.qid + '_' + state.answer_6.answerValue
              : null,
            RecaptchaToken: _recaptchaToken,
          },
        }

        /*
        const response = await axios.post('/api/caregiver-survey-service/', {
          CampaignId:
            parsedQString['cid'] !== undefined ? parsedQString['cid'] : null,
          JobId:
            parsedQString['jid'] !== undefined ? parsedQString['jid'] : null,
          EndoMdmId:
            parsedQString['mid'] !== undefined ? parsedQString['mid'] : null,
          SubscriberId:
            parsedQString['sid'] !== undefined ? parsedQString['sid'] : null,
          TacticId:
            parsedQString['tid'] !== undefined ? parsedQString['tid'] : null,
          Q1: state.answer_1.question
            ? state.answer_1.question.qid + '_' + state.answer_1.answerValue
            : null,
          Q2: state.answer_2.question
            ? state.answer_2.question.qid + '_' + state.answer_2.answerValue
            : null,
          Q3: state.answer_3.question
            ? state.answer_3.question.qid + '_' + state.answer_3.answerValue
            : null,
          Q4: state.answer_4.question
            ? state.answer_4.question.qid + '_' + state.answer_4.answerValue
            : null,
          Q5: state.answer_5.question
            ? state.answer_5.question.qid + '_' + state.answer_5.answerValue
            : null,
          Q6: state.answer_6.question
            ? state.answer_6.question.qid + '_' + state.answer_6.answerValue
            : null,
          RecaptchaToken: _recaptchaToken,
        },)*/

        const response = await axios(options)

        if (parsedQString['sid']) {
          setTimeout(() => {
            setIsLoading(false)
          }, 200)
        }
        console.log('Response from ReCaptcha,', response)
        showValuesForDebug()
        setErrorMsg('')
      } catch (e) {
        showValuesForDebug()
        setErrorMsg(JSON.stringify(e))
        console.log(e)
      }
    } else {
      setErrorMsg('Error: Subscriber ID is required!')
      showValuesForDebug()
    }
  }

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('submitSupprelinSurvey')
    // console.log('Troken,', token)
    getData(token)
    // Do whatever you want with the token
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    // console.log('state is,', state)
    // console.log('ParsedString,', parsedQString)
    // getData()
    // if (parsedQString['sid']) {
    //   setTimeout(() => {
    //     setIsLoading(false)
    //   }, 1000)
    // }
  }, [])
  return (
    <div className="slide-wrapper">
      <div className="slider-inner-container">
        {isLoading ? (
          <div
            style={{
              position: 'fixed',
              height: '100vh',
              width: '100vw',
              top: 0,
              left: 0,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ fontSize: 16, color: errorMsg ? 'red' : '' }}>
                {errorMsg != '' ? errorMsg : 'Processing data...'}
              </p>
            </div>
          </div>
        ) : (
          <>
            {errorMsg == '' ? (
              <>
                <div className="content-wrapper">
                  <p className="slide-head">Thanks for sharing!</p>
                  <div className="slide-body-container">
                    <p>
                      The information you provided will remain confidential and
                      will only be used to improve the support offered by
                      SUPPRELIN
                      <sup>&reg;</sup> LA.
                    </p>
                  </div>
                </div>
                <div className="button-wrapper left-align">
                  <a href="/patients/caregiver-signup/">
                    <button className="get-started">Learn More</button>
                  </a>
                </div>
              </>
            ) : (
              <div>
                <p style={{ fontSize: 16, color: errorMsg ? 'red' : '' }}>
                  {errorMsg != '' ? errorMsg : 'Processing data...'}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default End
