import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Grid, Row, Col } from 'react-flexbox-grid'
import posed, { PoseGroup } from 'react-pose'

import EventEmitter from '../../../lib/emitter'
import Header from '../header'
import Footer from '../footer'
import StickyISI from '../../sticky-isi'
import Interstitial from '../../interstitial'
import MobileNav from '../../mobile-nav'
import ShareContainer from '../../share-container'

import './layout.css'
import '../../../scss/hamburgers/hamburgers.scss'
import '../../../scss/supprelin-theme.scss'
import ExternalModal from '../../external-modal'

import './cookieOR.css'

import '../dfa-theme-alt.scss'

//Animation for the mobile//off canvas menu
const PosedAnimation = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      default: { duration: 200 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

const PosedOverlayAnimation = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    transition: {
      default: { duration: 200 },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
})

let interstitialSubscription = null
let externalSubscription = null
let closeOffCanvasSubscription = null
let Subscription
let Subscription2

class Layout extends Component {
  state = {
    showMobileMenu: false,
    headerHeight: 0,
    footerHeight: 0,
    showInterstitial: false,
    showExternalModal: false,
    interstitialAwayURL: '',
    externalAwayURL: '',
    isiHeight: 115,
    isCollapsed: true,
  }

  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  //Toggle mobile/offcanvas menu
  toggleMenu = () => {
    console.log('toggle menu')
    this.setState((prevState) => ({
      showMobileMenu: !prevState.showMobileMenu,
    }))
  }

  resize = () => {
    //console.log('fired')

    this.setState({
      headerHeight: this.headerRef.clientHeight,
    })

    /**
     * If ISI is not needed remove the following state (footerHeight).
     * Also remove 'StickyISI' component call in this page.
     */
    this.setState({
      footerHeight: this.footerRef.clientHeight,
    })
  }

  //Close the Interstitial
  closeInterstitial = () => {
    this.setState({
      showInterstitial: false,
    })
  }

  //Close the External Modal
  closeExternalModal = () => {
    this.setState({
      showExternalModal: false,
    })
  }

  componentDidMount() {
    const this_ = this
    setTimeout(function () {
      //Trigger resize on component load
      this_.resize()
      //Trigger resize on window resize
      window.addEventListener('resize', this_.resize)
    }, 200)
    let windowWidth = window.innerWidth

    //Triggered from ISI Component
    Subscription = EventEmitter.addListener('ISICollapseEvent', (data) => {
      console.log('fire from isi collapse event')
      if (data) {
        const isiHeight = windowWidth <= 500 ? 150 : 115
        this.setState({
          isiHeight: isiHeight,
          isCollapsed: !this.state.isCollapsed,
        })
      }
    })

    //Triggered from ISI Component
    Subscription2 = EventEmitter.addListener('ISIExpandEvent', (data) => {
      console.log('fire from isi collapse event')
      if (data) {
        const isiHeight = windowWidth <= 500 ? 270 : 185
        this.setState({
          isiHeight: isiHeight,
          isCollapsed: !this.state.isCollapsed,
        })
      }
    })
    //Subscription for interstitial modal window
    interstitialSubscription = EventEmitter.addListener(
      'OPEN_INTERSTITIAL',
      (data) => {
        this.setState({ interstitialAwayURL: data.url, showInterstitial: true })
      }
    )
    //Subscription for external modal window
    externalSubscription = EventEmitter.addListener(
      'OPEN_EXTERNAL_MODAL',
      (data) => {
        this.setState({ externalAwayURL: data.url, showExternalModal: true })
      }
    )
    closeOffCanvasSubscription = EventEmitter.addListener(
      'CLOSE_MOBILEMENU',
      (data) => {
        this.toggleMenu()
      }
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    interstitialSubscription && interstitialSubscription.remove()
    externalSubscription && externalSubscription.remove()
    closeOffCanvasSubscription && closeOffCanvasSubscription.remove()
    Subscription && Subscription.remove()
    Subscription2 && Subscription.remove()
  }

  render() {
    const { showMobileMenu, headerHeight } = this.state

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <Fragment>
            <Helmet
              title={
                this.props.meta && this.props.meta.title
                  ? this.props.meta.title
                  : data.site.siteMetadata.title
              }
              meta={[
                {
                  name: 'description',
                  content: this.props.meta && this.props.meta.description,
                },
                {
                  name: 'keywords',
                  content: this.props.meta && this.props.meta.keywords,
                },
              ]}
            >
              <html lang="en" />
              <script src="https://cdn.polyfill.io/v2/polyfill.js?features=es6&flags=gated"></script>
              <script
                src="https://code.jquery.com/jquery-3.4.1.min.js"
                integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
                crossorigin="anonymous"
              ></script>
              <meta
                name="facebook-domain-verification"
                content="w4nip4vg6djlowo2k9jf7aeyh1m7ul"
              />
            </Helmet>
            <div className="dfa-app alt-layout">
              <header
                className="base-header"
                ref={(_header) => {
                  this.headerRef = _header
                }}
                style={{ zIndex: this.state.showMobileMenu ? 0 : 1 }}
              >
                <Header
                  showMobileMenu={this.state.showMobileMenu}
                  handlr={this.toggleMenu}
                  headerHeight={headerHeight}
                />
              </header>
              {/*Body starts here*/}
              <div className="base-container">
                <div
                  className="base-body"
                  style={{ paddingTop: this.state.headerHeight }}
                >
                  <Grid fluid className="ie-width">
                    {this.props.children}
                  </Grid>
                </div>
              </div>
              {/*Body ends here*/}
              <a name="isi_jump" id="isi_jump">
                &nbsp;
              </a>
              <footer
                className="base-footer"
                ref={(_footer) => {
                  this.footerRef = _footer
                }}
              >
                {/*If ISI is not required the StickyISI component should be removed*/}
                <StickyISI
                  FooterHeight={this.state.footerHeight}
                  isiExpanded={this.state.isiExpanded}
                  isiHeight={this.state.isiHeight}
                  isCollapsed={this.state.isCollapsed}
                />
                {/** ISI ends here */}

                <Footer jobCode="SP-05671/November 2022" />
              </footer>

              <Row>
                <Col xs={10} md={6}>
                  <ShareContainer indication={'PAT'} />
                </Col>
              </Row>
              <Interstitial
                visible={this.state.showInterstitial}
                onClickAway={this.closeInterstitial}
                interstitialAwayURL={this.state.interstitialAwayURL}
              />
              <ExternalModal
                visible={this.state.showExternalModal}
                onClickAway={this.closeExternalModal}
                externalAwayURL={this.state.externalAwayURL}
              />
              {/*Off Canvas menu starts here*/}
              <PoseGroup>
                {showMobileMenu && [
                  <PosedAnimation key="anim1" className="posed-anim-wrapper">
                    <MobileNav
                      headerHeight={headerHeight}
                      toggleMenu={this.toggleMenu}
                    />
                  </PosedAnimation>,
                ]}
              </PoseGroup>
              <PoseGroup>
                {showMobileMenu && [
                  <PosedOverlayAnimation key="anim2">
                    <div className="menu-overlay" onClick={this.toggleMenu}>
                      &nbsp;
                    </div>
                  </PosedOverlayAnimation>,
                ]}
              </PoseGroup>
              {/*Off Canvas menu ends here*/}
            </div>
          </Fragment>
        )}
      />
    )
  }
}

export default Layout
