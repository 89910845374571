import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../redux/_context'
import { actionTypes } from '../redux/_reducer'

function Question4() {
  const { state, dispatch } = useContext(Context)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState({
    qid: '',
    qval: '',
  })
  const [enableNext, setEnableNext] = useState(true)

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])

  const handleCheckBoxUpdate = (e) => {
    const { value, checked } = e.target

    // console.log(selectedCheckboxes)

    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, value])
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((e) => e !== value))
    }
  }

  const goBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 3,
    })
  }
  const goNext = () => {
    dispatch({
      type: actionTypes.SET_ANSWER_4,
      payload: {
        ...{
          question: selectedQuestion,
          answerValue: selectedOption,
        },
      },
    })
    if (state.answer_1.answerValue == 'no') {
      //Submit here

      dispatch({
        type: actionTypes.SET_CURRENT_STEP,
        payload: -1,
      })
    } else {
      dispatch({
        type: actionTypes.SET_CURRENT_STEP,
        payload: 5,
      })
    }
  }

  useEffect(() => {
    state.answer_1.answerValue == 'yes' && state.answer_3.answerValue == 'yes'
      ? setSelectedQuestion({
          qid: '4aa',
          qval: "Did you and your child's doctor choose treatment with SUPPRELIN<sup>&reg;</sup> LA?",
        })
      : state.answer_1.answerValue == 'yes' &&
        state.answer_3.answerValue == 'no'
      ? setSelectedQuestion({
          qid: '4ab',
          qval: "Are you and your child's doctor considering SUPPRELIN<sup>&reg;</sup> LA as a treatment option?",
        })
      : state.answer_1.answerValue == 'no' &&
        state.answer_3.answerValue == 'yes'
      ? setSelectedQuestion({
          qid: '4ba',
          qval: "Please select the reason(s) why you and your child's doctor are considering SUPPRELIN<sup>&reg;</sup>  LA as a treatment option if your child is diagnosed with CPP: ",
        })
      : state.answer_1.answerValue == 'no' && state.answer_3.answerValue == 'no'
      ? setSelectedQuestion({
          qid: '4bb',
          qval: "Please select the reason(s) why you and your child's doctor are NOT considering SUPPRELIN<sup>&reg;</sup> LA as a treatment option if your child is diagnosed with CPP: ",
        })
      : setSelectedQuestion('')
  }, [])

  useEffect(() => {
    selectedOption || selectedCheckboxes.length > 0
      ? setEnableNext(true)
      : setEnableNext(false)

    selectedCheckboxes.length > 0 &&
      setSelectedOption(selectedCheckboxes.join(','))
  }, [selectedOption, selectedCheckboxes])

  return (
    <div className="slide-wrapper question">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <div className="slide-head">
            <span>4.</span>
            <span dangerouslySetInnerHTML={{ __html: selectedQuestion.qval }} />
          </div>
          <div className="slide-body-container">
            {state.answer_1.answerValue == 'yes' && (
              <div className="radio-group">
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer4_a_a"
                    name="answer4a"
                    value="yes"
                    onChange={() => setSelectedOption('yes')}
                  />
                  <label htmlFor="answer4_a_a">Yes</label>
                </div>
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer4_a_b"
                    name="answer4a"
                    value="no"
                    onChange={() => setSelectedOption('no')}
                  />
                  <label htmlFor="answer4_a_b">No</label>
                </div>
              </div>
            )}
            {state.answer_1.answerValue == 'no' &&
              state.answer_3.answerValue == 'yes' && (
                <div>
                  <p>(Choose all that apply.)</p>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="convenience"
                      name="convenience"
                      value="convenience"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="convenience">
                      Convenience of a once-yearly treatment
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="continuous"
                      name="continuous"
                      value="continuous"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="continuous">
                      Continuous delivery of treatment each and every day
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="no-injection"
                      name="no-injection"
                      value="no-injection"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="no-injection">
                      Did not want an injection
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="all-resources-information"
                      name="all-resources-information"
                      value="all-resources-information"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="all-resources-information">
                      All of the resources and information provided
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="doctor-recommended"
                      name="doctor-recommended"
                      value="doctor-recommended"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="doctor-recommended">
                      My child's doctor recommended it
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="all-above"
                      name="all-above"
                      value="all-above"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="all-above">All of the above</label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              )}
            {state.answer_1.answerValue == 'no' &&
              state.answer_3.answerValue == 'no' && (
                <div>
                  <p>(Choose all that apply.)</p>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="cost_high"
                      name="cost_high"
                      value="cost-too-high"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="cost_high">
                      The cost is too high/health insurance does not cover it
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="no_procedure"
                      name="no_procedure"
                      value="no-procedure"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="no_procedure">
                      I do not want my child to undergo another procedure
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="not_offered"
                      name="not_offered"
                      value="not-offered"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="not_offered">
                      My child’s doctor does not offer it
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="not_recommended"
                      name="not_recommended"
                      value="not-recommended"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="not_recommended">
                      My child’s doctor does not recommend it for my child
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="different_treatment"
                      name="different_treatment"
                      value="different-treatment"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="different_treatment">
                      I am considering a different treatment
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="not_considering_treatment"
                      name="not_considering_treatment"
                      value="not-considering-treatment"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="not_considering_treatment">
                      I am not considering treatment
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn-action" onClick={goBack}>
            &lt; Back
          </button>
          <button
            className={`btn-action ${!enableNext ? 'disabled' : ''}`}
            onClick={goNext}
            disabled={!enableNext}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question4
