import React, { useState, useContext } from 'react'
import { Context } from '../redux/_context'
import { actionTypes } from '../redux/_reducer'

function Question1() {
  const { state, dispatch } = useContext(Context)
  const [selectedOption, setSelectedOption] = useState(null)
  const goBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 0,
    })
  }
  const goNext = () => {
    dispatch({
      type: actionTypes.SET_ANSWER_1,
      payload: {
        ...{
          question: {
            qid: '1',
            qval: 'Has your child been diagnosed with central precocious puberty (CPP) by a healthcare professional?',
          },
          answerValue: selectedOption,
        },
      },
    })
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 2,
    })
  }

  return (
    <div className="slide-wrapper question">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <div className="slide-head">
            <span>1.</span>
            <span>
              Has your child been diagnosed with central precocious puberty
              (CPP) by a healthcare professional?
            </span>
          </div>
          <div className="slide-body-container">
            <div className="radio-group">
              <div className="option-block">
                <input
                  type="radio"
                  name="answer1"
                  id="1_Yes"
                  onChange={() => setSelectedOption('yes')}
                />
                <label htmlFor="1_Yes">Yes</label>
              </div>
              <div className="option-block">
                <input
                  type="radio"
                  name="answer1"
                  id="1_No"
                  onChange={() => setSelectedOption('no')}
                />
                <label htmlFor="1_No">No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn-action" onClick={goBack}>
            &lt; Back
          </button>
          <button
            className={`btn-action ${!selectedOption ? 'disabled' : ''}`}
            onClick={goNext}
            disabled={!selectedOption ? true : false}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question1
