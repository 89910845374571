import React, { useContext } from 'react'
import { Context } from '../redux/_context'
import Start from './_start'
import Question1 from './_question1'
import Question2 from './_question2'
import Question3 from './_question3'
import Question4 from './_question4'
import Question5 from './_question5'
import Question6 from './_question6'
import End from './_end'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function _questionsContainer() {
  const { state, dispatch } = useContext(Context)
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcsqPIjAAAAAPg7jo7PuJU28PnadTAnQ9WWh5x6">
      {state.currentStep === 0 ? (
        <Start />
      ) : state.currentStep === 1 ? (
        <Question1 />
      ) : state.currentStep === 2 ? (
        <Question2 />
      ) : state.currentStep === 3 ? (
        <Question3 />
      ) : state.currentStep === 4 ? (
        <Question4 />
      ) : state.currentStep === 5 ? (
        <Question5 />
      ) : state.currentStep === 6 ? (
        <Question6 />
      ) : (
        <End />
      )}
    </GoogleReCaptchaProvider>
  )
}

export default _questionsContainer
