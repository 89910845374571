import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../redux/_context'
import { actionTypes } from '../redux/_reducer'

function Question5() {
  const { state, dispatch } = useContext(Context)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState({
    qid: '',
    qval: '',
  })
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])

  const handleCheckBoxUpdate = (e) => {
    const { value, checked } = e.target

    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, value])
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((e) => e !== value))
    }
  }

  const goBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 4,
    })
  }
  const goNext = () => {
    dispatch({
      type: actionTypes.SET_ANSWER_5,
      payload: {
        ...{
          question: selectedQuestion,
          answerValue: selectedOption,
        },
      },
    })

    state.answer_1.answerValue == 'yes' && state.answer_4.answerValue == 'no'
      ? dispatch({
          type: actionTypes.SET_CURRENT_STEP,
          payload: -1,
        })
      : dispatch({
          type: actionTypes.SET_CURRENT_STEP,
          payload: 6,
        })
  }

  useEffect(() => {
    state.answer_1.answerValue == 'yes' && state.answer_4.answerValue == 'yes'
      ? setSelectedQuestion({
          qid: '5aa',
          qval: "Please select the reason(s) why you and your child's doctor are considering/chose SUPPRELIN<sup>&reg;</sup> LA as a treatment option:",
        })
      : state.answer_1.answerValue == 'yes' &&
        state.answer_4.answerValue == 'no'
      ? setSelectedQuestion({
          qid: '5ab',
          qval: "Please select the reason(s) why you and your child's doctor are NOT considering/choosing SUPPRELIN<sup>&reg;</sup> LA as a treatment option:",
        })
      : setSelectedQuestion('')
  }, [])
  useEffect(() => {
    selectedCheckboxes.length > 0 &&
      setSelectedOption(selectedCheckboxes.join(','))
  }, [selectedCheckboxes])

  return (
    <div className="slide-wrapper question">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <div className="slide-head">
            <span>5.</span>
            <span dangerouslySetInnerHTML={{ __html: selectedQuestion.qval }} />
          </div>
          <div className="slide-body-container">
            {state.answer_1.answerValue == 'yes' &&
              state.answer_4.answerValue == 'yes' && (
                <div>
                  <p>(Choose all that apply.)</p>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="convenience"
                      name="convenience"
                      value="convenience"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="convenience">
                      Convenience of a once-yearly treatment
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="continuous"
                      name="continuous"
                      value="continuous"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="continuous">
                      Continuous delivery of treatment each and every day
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="no_injection"
                      name="no_injection"
                      value="no-injection"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="no_injection">
                      Did not want an injection
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="all_resources_information"
                      name="all_resources_information"
                      value="all-resources-information"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="all_resources_information">
                      All of the resources and information provided
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="doctor_recommended"
                      name="doctor_recommended"
                      value="doctor-recommended"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="doctor_recommended">
                      My child's doctor recommended it
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="all_above"
                      name="all_above"
                      value="all-above"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="all_above">All of the above</label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              )}
            {state.answer_1.answerValue == 'yes' &&
              state.answer_4.answerValue == 'no' && (
                <div>
                  <p>(Choose all that apply.)</p>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="cost_too_high"
                      name="cost_too_high"
                      value="cost-too-high"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="cost_too_high">
                      The cost is too high/health insurance does not cover it
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="no_procedure"
                      name="no_procedure"
                      value="no-procedure"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="no_procedure">
                      I did not want my child to undergo another procedure
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="not_offered"
                      name="not_offered"
                      value="not-offered"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="not_offered">
                      My child's doctor did not offer it
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="different_treatment"
                      name="different-treatment"
                      value="different_treatment"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="different_treatment">
                      I chose a different treatment
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="not_considering_treatment"
                      name="not_considering_treatment"
                      value="not-considering-treatment"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="not_considering_treatment">
                      I chose not to treat
                    </label>
                  </div>
                  <div className="cbox-option-container">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      onChange={handleCheckBoxUpdate}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn-action" onClick={goBack}>
            &lt; Back
          </button>
          <button
            className={`btn-action ${selectedCheckboxes < 1 ? 'disabled' : ''}`}
            onClick={goNext}
            disabled={selectedCheckboxes < 1 ? true : false}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question5
