import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../redux/_context'
import { actionTypes } from '../redux/_reducer'

function Question3() {
  const { state, dispatch } = useContext(Context)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState({
    qid: '',
    qval: '',
  })
  const goBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 2,
    })
  }
  const goNext = () => {
    dispatch({
      type: actionTypes.SET_ANSWER_3,
      payload: {
        ...{
          question: selectedQuestion,
          answerValue: selectedOption,
        },
      },
    })
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 4,
    })
  }

  useEffect(() => {
    state.answer_1.answerValue == 'yes'
      ? setSelectedQuestion({
          qid: '3a',
          qval: "Have you and your child's doctor chosen a CPP treatment option?",
        })
      : setSelectedQuestion({
          qid: '3b',
          qval: "Do you and your child's doctor plan to consider SUPPRELIN<sup>&reg;</sup> LA as a treatment option if your child is diagnosed with CPP?",
        })
  }, [])

  return (
    <div className="slide-wrapper question">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <div className="slide-head">
            <span>3.</span>
            <span dangerouslySetInnerHTML={{ __html: selectedQuestion.qval }} />
          </div>
          <div className="slide-body-container">
            <div className="radio-group">
              <div className="option-block">
                <input
                  type="radio"
                  id="answer3_a_a"
                  name="answer3a"
                  value="yes"
                  onChange={() => setSelectedOption('yes')}
                />
                <label htmlFor="answer3_a_a">Yes</label>
              </div>
              <div className="option-block">
                <input
                  type="radio"
                  id="answer3_a_b"
                  name="answer3a"
                  value="no"
                  onChange={() => setSelectedOption('no')}
                />
                <label htmlFor="answer3_a_b">No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn-action" onClick={goBack}>
            &lt; Back
          </button>
          <button
            className={`btn-action ${!selectedOption ? 'disabled' : ''}`}
            onClick={goNext}
            disabled={!selectedOption ? true : false}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question3
