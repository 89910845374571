import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../redux/_context'
import { actionTypes } from '../redux/_reducer'

function Question6() {
  const { state, dispatch } = useContext(Context)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState({
    qid: '',
    qval: '',
  })
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const handleCheckBoxUpdate = (e) => {
    const { value, checked } = e.target

    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, value])
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((e) => e !== value))
    }
  }
  const goBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 5,
    })
  }

  const goNext = () => {
    dispatch({
      type: actionTypes.SET_ANSWER_6,
      payload: {
        ...{
          question: {
            qid: '6a',
            qval: 'Please select the reason(s) why you and your child’s doctor chose SUPPRELIN® LA as treatment option: Has your child been diagnosed with central precocious puberty (CPP) by a healthcare professional?',
          },
          answerValue: selectedOption,
        },
      },
    })

    // submit first and execute below
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: -1,
    })
  }

  useEffect(() => {
    selectedCheckboxes.length > 0 &&
      setSelectedOption(selectedCheckboxes.join(','))
  }, [selectedCheckboxes])

  return (
    <div className="slide-wrapper question">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <div className="slide-head">
            <span>6.</span>
            <span>
              Please select the phrase(s) that best describe how you/your child
              feel about SUPPRELIN<sup>&reg;</sup> LA so far:
            </span>
          </div>
          <div className="slide-body-container">
            <div>
              <p>(Choose all that apply.)</p>
              <div className="cbox-option-container">
                <input
                  type="checkbox"
                  id="happy-treatment-completed"
                  name="happy_treatment_completed"
                  value="happy-treatment-completed"
                  onChange={handleCheckBoxUpdate}
                />
                <label htmlFor="happy_treatment_completed">
                  Happy treatment was completed
                </label>
              </div>
              <div className="cbox-option-container">
                <input
                  type="checkbox"
                  id="awaiting-treatment"
                  name="awaiting_treatment"
                  value="awaiting-treatment"
                  onChange={handleCheckBoxUpdate}
                />
                <label htmlFor="awaiting_treatment">
                  Anxiously awaiting treatment
                </label>
              </div>
              <div className="cbox-option-container">
                <input
                  type="checkbox"
                  id="supported"
                  name="supported"
                  value="supported"
                  onChange={handleCheckBoxUpdate}
                />
                <label htmlFor="supported">
                  Supported by all of the resources and information provided
                </label>
              </div>
              <div className="cbox-option-container">
                <input
                  type="checkbox"
                  id="thankful"
                  name="thankful"
                  value="thankful"
                  onChange={handleCheckBoxUpdate}
                />
                <label htmlFor="thankful">
                  Thankful my child's doctor offered it
                </label>
              </div>
              <div className="cbox-option-container">
                <input
                  type="checkbox"
                  id="all-above"
                  name="all_above"
                  value="all-above"
                  onChange={handleCheckBoxUpdate}
                />
                <label htmlFor="all_above">All of the above</label>
              </div>
              <div className="cbox-option-container">
                <input
                  type="checkbox"
                  id="other"
                  name="other"
                  value="other"
                  onChange={handleCheckBoxUpdate}
                />
                <label htmlFor="other">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn-action" onClick={goBack}>
            &lt; Back
          </button>
          <button
            className={`btn-action ${selectedCheckboxes < 1 ? 'disabled' : ''}`}
            onClick={goNext}
            disabled={selectedCheckboxes < 1 ? true : false}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question6
