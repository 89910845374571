import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import imgEndoLogo from '../../../images/endo-logo.webp'
import './footer.scss'

const Footer = ({ jobCode }) => (
  <div className="outer-container">
    <div className="inner-container">
      <Grid>
        <Row className="endo-footer-logo-block" center="xs">
          <Col xs={12} md={6}>
            <a
              className="link-footer-logo"
              href="https://www.endo.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block' }}
            >
              <img
                src={imgEndoLogo}
                className="footer-logo"
                alt="Endo Logo"
                title="Endo Logo"
              />
            </a>
          </Col>
          <Col xs={12} md={6} className="rx-block">
            <p className="txt-rx-only">&nbsp;</p>
            <p className="footer">
              SUPPRELIN<sup>&reg;</sup> is a registered trademark of Endo
              International plc or one of its affiliates.
              <br />
              &copy; 2024 Endo, Inc. or one of its affiliates. All
              rights reserved.
              <br />
              <strong>{`${jobCode}`}</strong>{' '}
              <span className="hide-in-mobile">&nbsp;</span>
              www.supprelinla.com&nbsp;&nbsp;1-800-462-ENDO (3636)
            </p>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
)

export default Footer
