export const InitialState = {
  currentStep: 0,
  answer_1: { question: null, answerValue: null },
  answer_2: { question: null, answerValue: null },
  answer_3: { question: null, answerValue: null },
  answer_4: { question: null, answerValue: null },
  answer_5: { question: null, answerValue: null },
  answer_6: { question: null, answerValue: null },
}

export const actionTypes = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_ANSWER_1: 'SET_ANSWER_1',
  SET_ANSWER_2: 'SET_ANSWER_2',
  SET_ANSWER_3: 'SET_ANSWER_3',
  SET_ANSWER_4: 'SET_ANSWER_4',
  SET_ANSWER_5: 'SET_ANSWER_5',
  SET_ANSWER_6: 'SET_ANSWER_6',
}

export const Reducer = (state, action) => {
  //console.log(state)
  switch (action.type) {
    case actionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      }
    case actionTypes.SET_ANSWER_1:
      return {
        ...state,
        answer_1: {
          question: action.payload.question,
          answerValue: action.payload.answerValue,
        },
      }
    case actionTypes.SET_ANSWER_2:
      return {
        ...state,
        answer_2: {
          question: action.payload.question,
          answerValue: action.payload.answerValue,
        },
      }
    case actionTypes.SET_ANSWER_3:
      return {
        ...state,
        answer_3: {
          question: action.payload.question,
          answerValue: action.payload.answerValue,
        },
      }
    case actionTypes.SET_ANSWER_4:
      return {
        ...state,
        answer_4: {
          question: action.payload.question,
          answerValue: action.payload.answerValue,
        },
      }
    case actionTypes.SET_ANSWER_5:
      return {
        ...state,
        answer_5: {
          question: action.payload.question,
          answerValue: action.payload.answerValue,
        },
      }
    case actionTypes.SET_ANSWER_6:
      return {
        ...state,
        answer_6: {
          question: action.payload.question,
          answerValue: action.payload.answerValue,
        },
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
