import React, { useContext } from 'react'
import { Context } from '../redux/_context'

function Start() {
  const { state, dispatch } = useContext(Context)

  const goNext = () => {
    dispatch({
      type: 'SET_CURRENT_STEP',
      payload: 1,
    })
  }
  return (
    <div className="slide-wrapper">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <p className="slide-head">Sharing is part of caring</p>
          <div className="slide-body-container">
            <p>
              We appreciate your willingness to share your central precocious
              puberty (CPP) journey with us.
              <br />
              This survey should only take around 5 minutes.
            </p>
            <p>
              The information you provide will remain confidential and only be
              used to improve the support offered by SUPPRELIN<sup>&reg;</sup>{' '}
              LA.
            </p>
          </div>
        </div>
        <div className="button-wrapper left-align">
          <button className="get-started" onClick={goNext}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  )
}

export default Start
