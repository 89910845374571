import React, { useReducer } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme-alt/layout/'
import { Reducer, InitialState } from './redux/_reducer'
import { Context } from './redux/_context'
import QuestionContainer from './components/_questionsContainer'
import './caregiver-survey.scss'

const metaTags = {
  description: 'SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Survey',
  title: 'Sign up | SUPPRELIN® LA (histrelin acetate) Caregiver Insight Survey',
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState)
  const value = { state, dispatch }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

function CareGiverPage() {
  return (
    <Layout meta={metaTags}>
      <Row className="care-giver-page">
        <Col xs={12}>
          <Provider>
            <QuestionContainer />
          </Provider>
        </Col>
      </Row>
    </Layout>
  )
}

export default CareGiverPage
