import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../redux/_context'
import { actionTypes } from '../redux/_reducer'

function Question2() {
  const { state, dispatch } = useContext(Context)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState({
    qid: '',
    qval: '',
  })
  const goBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 1,
    })
  }
  const goNext = () => {
    dispatch({
      type: actionTypes.SET_ANSWER_2,
      payload: {
        ...{
          question: selectedQuestion,
          answerValue: selectedOption,
        },
      },
    })
    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: 3,
    })
  }

  useEffect(() => {
    state.answer_1.answerValue == 'yes'
      ? setSelectedQuestion({
          qid: '2a',
          qval: 'How long has it been since your child was diagnosed?',
        })
      : setSelectedQuestion({
          qid: '2b',
          qval: 'Have you met with/scheduled an appointment with a healthcare professional to discuss CPP?',
        })
  }, [])

  return (
    <div className="slide-wrapper question">
      <div className="slider-inner-container">
        <div className="content-wrapper">
          <div className="slide-head">
            <span>2.</span>
            <span dangerouslySetInnerHTML={{ __html: selectedQuestion.qval }} />
          </div>
          <div className="slide-body-container">
            {state.answer_1.answerValue == 'yes' ? (
              <div className="radio-group">
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer2_a_a"
                    name="answer2a"
                    value="Less than 1 year"
                    onChange={() => setSelectedOption('less-than-1-year')}
                  />
                  <label htmlFor="answer2_a">Less than 1 year</label>
                </div>
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer2_a_b"
                    name="answer2a"
                    value="1 to 3 years"
                    onChange={() => setSelectedOption('1-3-years')}
                  />
                  <label htmlFor="answer2_a_b">1 to 3 years</label>
                </div>
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer2_c"
                    name="answer2a"
                    value="More than 3 years"
                    onChange={() => setSelectedOption('more-than-3-years')}
                  />
                  <label htmlFor="answer2_a_c">More than 3 years</label>
                </div>
              </div>
            ) : (
              <div className="radio-group">
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer2_b_a"
                    name="answer2b"
                    value="yes"
                    onChange={() => setSelectedOption('yes')}
                  />
                  <label htmlFor="answer2_b_a">Yes</label>
                </div>
                <div className="option-block">
                  <input
                    type="radio"
                    id="answer2_b_b"
                    name="answer2b"
                    value="no"
                    onChange={() => setSelectedOption('no')}
                  />
                  <label htmlFor="answer2_b_b">No</label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn-action" onClick={goBack}>
            &lt; Back
          </button>
          <button
            className={`btn-action ${!selectedOption ? 'disabled' : ''}`}
            onClick={goNext}
            disabled={!selectedOption ? true : false}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question2
